import React from "react";
import Layout from "../layouts/en";
import Seo from "../components/info-site/Seo";
// import HomepageInfoSection from "../components/info-site/HomepageInfoSection";
// import WhyTyviso from "../components/info-site/WhyTyviso";
// import CaseStudyOverview from "../components/info-site/CaseStudyOverview";
// import Stage from "../components/info-site/Stage";
// import BiggestChallanges from "../components/info-site/BiggestChallanges";
// import HomepageHowItWorks from "../components/info-site/HomepageHowItWorks";
// import VideoPlayer from "../components/styleguide/VideoPlayer"
// import CompaniesTestimonials from "../components/info-site/CompaniesTestimonials";
// import StageContent from "../components/info-site/StageContent";

// import BrandPartnerships from "../components/info-site/BrandPartnerships";
// import rewardsMainFeatures from "../data/rewardsMainFeatures";
// import SideContentWithImg from "../components/info-site/SideContentWithImg";

// import { homepageTestimonials } from "../data/companiesTestimonials";
import ProductHeader from "../components/info-site/ProductHeader";
import ProductBenefits from "../components/info-site/ProductBenefits";
import ProductHowItWorks from "../components/info-site/ProductHowItWorks";
import ProductReview from "../components/info-site/ProductReview";
import GetStarted from "../components/info-site/GetStarted";

import EE from '../assets/ee-black.png'
import Wild from '../assets/wild-black.png'
import SS from '../assets/secret-sales.png'
import ClearPay from '../assets/clearpay.png'
import PlusNet from '../assets/plusnet-black.png'

import ProdBenefits1 from '../assets/product-benefits-1.png'
import ProdBenefits2 from '../assets/product-benefits-2.png'
import ProdBenefits3 from '../assets/product-benefits-8.png'
import ProdBenefits4 from '../assets/product-benefits-9.png'
import ProdBenefits5 from '../assets/product-benefits-5.png'

import PartnersLogoImg from '../assets/partners-logo-tr.png'
import ClaimGiftImg from '../assets/claim-gift-tr.png'
import FreeGift from '../assets/product-rewards-2.png'

import PlusNetImg from '../assets/plusnet-white.png'

const TyvisoRewards = props => {
    return (
			<Layout location={props.location}>
				<Seo titleId="rewardsTitle" descriptionId="rewardsDescription" />

        <ProductHeader
					title="rewardsProductHeaderTitle"
					desc="rewardsProductHeaderDesc"
          list={[
            'One click activation',
            'No monthly fees',
            'No setup fees',
          ]}
          img={FreeGift}
          trusted="Trusted by 500+ brands"
					partners={[
						{
							id: 'ee',
							img: EE,
						},
						{
							id: 'wild',
							img: Wild,
						},
						{
							id: 'ss',
							img: SS,
						},
						{
							id: 'clearpay',
							img: ClearPay,
						},
						{
							id: 'plusnet',
							img: PlusNet,
						},
					]}
				/>

        <ProductBenefits
					title="rewardsProductBenefitsTitle"
          list={[
            {
              icon: ProdBenefits1,
              text: 'Reduced churn of existing customers'
            },
            {
              icon: ProdBenefits2,
              text: 'Stronger emotional connection and perception'
            },
            {
              icon: ProdBenefits3,
              text: 'Ongoing engagement through personalised communications'
            },
            {
              icon: ProdBenefits4,
              text: 'Brand differentiation in a crowded market'
            },
            {
              icon: ProdBenefits5,
              text: 'New partnership opportunities with other brands'
            }
          ]}
        />

        <ProductHowItWorks
					title="rewardsProductHowItWorksTitle"
          list={[
            {
              img: PartnersLogoImg,
              title: '1. Select',
              text: 'Choose from existing and exclusive offers of who you want to partner with'
            },
            {
              img: ClaimGiftImg,
              title: '2. Push',
              text: 'Drop & drag  effortlessly on your site using our tech in 30 seconds '
            },
            {
              img: FreeGift,
              title: '3. Engage',
              text: 'Watch your customers interact and your loyalty improve'
            },
          ]}
        />

        <ProductReview
          left={{
            title: 'Words from friends',
            quote: 'The Tyviso platform has seriously changed the way that  senior stakeholders see brand partnerships',
            logo: PlusNetImg
          }}
          right={{
            title: 'Stats from case studies',
            list: [
              '5% reduction in churn',
              '33% more time on site',
              '209% more likely update marketing preferences',
              '90% more likely to upgrade',
            ]
          }}
        />

        <GetStarted />
				{/* <Stage title="rewardsTitle" icon withoutMedia>
					<StageContent
						title="rewardsSideTitle"
						content="rewardsSideDescription"
						ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
						ctaText="homepageStagePrimaryCta"
						list={[
							'rewardsSideListOne',
							'rewardsSideListTwo',
							'rewardsSideListThree',
						]}
					/>
				</Stage>

				<CompaniesTestimonials
					title="rewardsCompanies"
					companies={homepageTestimonials}
				/>

				<VideoPlayer
					youtube={{
						src: 'https://www.youtube.com/embed/QYyrKu6Me24',
						title: 'Tyviso Rewards Setup Guide Video',
					}}
				/>

				<HomepageHowItWorks
					title="rewardsHowItWorksTitle"
					steps={[
						{
							id: 'rewardsHowItWorksOne',
							icon: 'blue',
						},
						{
							id: 'rewardsHowItWorksTwo',
							icon: 'turquoise',
						},
						{
							id: 'rewardsHowItWorksThree',
							icon: 'green',
						},
						{
							id: 'rewardsHowItWorksFour',
						},
					]}
				/>

				<BrandPartnerships title="mainFeatures">
					{rewardsMainFeatures.map(({ id, ...rest }) => (
						<SideContentWithImg lightParagraph key={id} {...rest} />
					))}
				</BrandPartnerships>

				<BiggestChallanges
					title="goals"
					centered
					cards={[
						{
							id: 'cpa',
							link: '/your-goals/acquire-new-customers',
							text: 'biggestChallangesCardOneTitle',
						},
						{
							id: 'ltv',
							link: '/your-goals/raise-customer-lifetime',
							text: 'biggestChallangesCardTwoTitle',
						},
						{
							id: 'revshare',
							link: '/your-goals/monetise-your-ecommerce',
							text: 'biggestChallangesCardThreeTitle',
						},
						{
							id: 'cro',
							link: '/your-goals/uplift-basket-completion',
							text: 'biggestChallangesCardFourTitle',
						},
					]}
				/>
				<GetStarted
					subtitle="rewardsEarningLoyalty"
					trustItems={[
						'recommendsTrustItemOne',
						'recommendsTrustItemTwo',
						'recommendsTrustItemThree',
					]}
				/> */}
			</Layout>
		);
};

export default TyvisoRewards;